import Questions from './index.vue';
import { createLocalVue, shallowMount } from '@vue/test-utils';

jest.mock('@/shared/helpers/toast');
jest.mock('@/services');

describe('questions', () => {
  const localVue = createLocalVue();

  let wrapper;
  const stubs = {
    HsGroup: true,
    HsInput: true,
    HsCheckbox: true,
    HsAlert: true,
    HsRadio: true,
    HsIcon: true,
    HsButton: {
      name: 'hs-button',
      template: '<button class="hsbutton-stub"></button>',
    },
    BCollapse: true,
    HsMultiselect: true,
    Editor: true,
  };

  const propsData = {
    activity: {
      exam_questions: [],
    },
  };

  const bvModal = {
    show: jest.fn(),
    hide: jest.fn(),
  };

  beforeEach(() => {
    wrapper = mountWrapper(localVue, stubs, propsData, bvModal);
  });

  describe('behaviour', () => {
    beforeEach(() => {
      wrapper.setProps({
        activity: {
          exam_questions: [
            {
              correct: 0,
              visible: false,
              title: 'Question',
              type: 'MultipleChoiceExamQuestion',
              multiple_choice_options: [{ title: 'teste' }],
            },
          ],
        },
      });
    });
    describe('when change question visibility', () => {
      it('should change visibility', () => {
        expect(wrapper.props().activity.exam_questions[0].visible).toBeFalsy();

        wrapper.find('#exam-edition-0').trigger('click');

        expect(wrapper.props().activity.exam_questions[0].visible).toBeTruthy();
      });
    });

    describe('when add new asnwer', () => {
      it('should add answer to question', () => {
        expect(wrapper.props().activity.exam_questions[0].multiple_choice_options).toHaveLength(1);

        wrapper.find('#add-answer-button').vm.$emit('click');

        expect(wrapper.props().activity.exam_questions[0].multiple_choice_options).toHaveLength(2);
      });
    });

    describe('when delete answer', () => {
      it('should open the delete modal', () => {
        expect(bvModal.show).not.toHaveBeenCalled();

        wrapper.find('#delete-answer-button').vm.$emit('click');

        expect(bvModal.show).toHaveBeenCalledWith('delete-question-answer-modal');
      });
      describe('when confirm modal', () => {
        it('should delete answer', () => {
          expect(wrapper.props().activity.exam_questions[0].multiple_choice_options).toHaveLength(1);

          wrapper.find('#delete-answer-button').vm.$emit('click');

          wrapper.find('#delete-question-answer-modal').vm.$emit('ok');

          expect(wrapper.props().activity.exam_questions[0].multiple_choice_options).toHaveLength(0);
        });
      });
    });

    describe('when add new question', () => {
      beforeEach(() => {
        wrapper.setProps({ activity: { exam_questions: [] } });
      });
      it('should add question to activity array', () => {
        expect(wrapper.props().activity.exam_questions).toHaveLength(0);

        wrapper.find('#add-question').vm.$emit('click');

        expect(wrapper.props().activity.exam_questions).toHaveLength(1);
      });
    });

    describe('when delete question', () => {
      it('should open the delete modal', () => {
        expect(bvModal.show).not.toHaveBeenCalled();

        wrapper.find('#delete-question-button').vm.$emit('click');

        expect(bvModal.show).toHaveBeenCalledWith('delete-question-modal');
      });
      describe('when confirm modal', () => {
        it('should delete question', () => {
          expect(wrapper.props().activity.exam_questions).toHaveLength(1);

          wrapper.find('#delete-question-button').vm.$emit('click');

          wrapper.find('#delete-question-modal').vm.$emit('ok');

          expect(wrapper.props().activity.exam_questions).toHaveLength(0);
        });
      });
    });
  });
});

function mountWrapper(localVue, stubs, propsData, bvModal) {
  return shallowMount(Questions, {
    localVue,
    stubs,
    propsData,
    mocks: {
      $t: jest.fn().mockImplementation(x => x),
      $bvModal: bvModal,
    },
  });
}
